import cachedPipe from './cached-pipe.js';
import format from './pipes/format.js';
import search from './pipes/search.js';
import filter from './pipes/filter.js';
import sort from './pipes/sort.js';
import paginate from './pipes/paginate.js';
const getContent = (_, [content]) => (content || []).map(row => ({
  formatted: undefined,
  raw: row
}));

export const createContentTransformer = () => cachedPipe([
  ['content', getContent],
  ['groupKey', 'sortBy', 'order', 'columnDefinitions', sort],
  ['language', 'columnDefinitions', 'formats', 'translations', format],
  ['keyword', 'columnDefinitions', 'searchKeys', 'searchIncludes', 'columnSettings', 'groupKey', search],
  ['filterValues', 'columnDefinitions', filter],
  ['hidePagination', 'pageSize', 'pageNumber', paginate]
]);
