import { AttributeUtils, EDSComponent } from '@emartech/eds-utils-component';

export class EDatagridGroups extends EDSComponent {
  #state;

  constructor() {
    super();

    this.#state = {
      contentKey: '',
      groups: []
    };
  }

  static componentName = 'datagrid-groups';

  connectedCallback() {
    this.#dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('groups.delete'));
  }

  static get observedAttributes() {
    return ['content-key', 'groups'];
  }

  set contentKey(value) {
    this.#state.contentKey = value;
    this.#dispatchEvent();
  }

  set groups(value) {
    this.#state.groups = AttributeUtils.convertToArray(value);
    this.#dispatchEvent();
  }

  #dispatchEvent() {
    if (!this.#state.contentKey) { return; }

    this.dispatchEvent(new CustomEvent('groups.update', {
      bubbles: true,
      detail: this.#state
    }));
  }
}

export default EDatagridGroups;
