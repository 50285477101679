import { html } from 'uhtml';
import classnames from 'clsx';
export class EPasswordTemplate {
    constructor(component) {
        this.refs = component.refs;
        this.state = component.state;
        this.events = component.events;
    }
    createElement() {
        return html `
      <div class="e-inputgroup">
        ${this.createInput()}
        ${this.state.isRevealButtonHidden ? '' : this.createButton()}
        ${this.state.isCopyButtonVisible ? this.createCopyButton() : ''}
      </div>
    `;
    }
    createInput() {
        const classList = classnames('e-input e-password__input e-inputgroup__item e-inputgroup__item-first', {
            'e-input-disabled': this.state.isDisabled,
            'e-input-readonly': this.state.isReadonly,
            'e-input-error': this.state.isInvalid,
            'e-inputgroup__item-last': this.state.isRevealButtonHidden && !this.state.isCopyButtonVisible
        });
        const type = this.state.isPasswordRevealed ? 'text' : 'password';
        return html `
      <input
        ref=${node => this.refs.input = node}
        class=${classList}
        type=${type}
        name=${this.state.inputName || null}
        placeholder=${this.state.placeholder || null}
        .value=${this.state.inputValue}
        ?disabled=${this.state.isDisabled}
        ?readonly=${this.state.isReadonly}
        @change=${this.events.onPasswordChange}
        @input=${this.events.onPasswordInput}
      >
    `;
    }
    createButton() {
        const classList = classnames('e-password__reveal_button e-btn e-btn-onlyicon e-inputgroup__item', {
            'e-btn-disabled': this.state.isDisabled,
            'e-inputgroup__item-last': !this.state.isCopyButtonVisible,
            'e-btn-active': this.state.isPasswordRevealed
        });
        const icon = this.state.isPasswordRevealed ? 'eye-slash' : 'eye';
        const tooltipContentTranslationKey = this.state.isPasswordRevealed ? 'hidePassword' : 'showPassword';
        const tooltipContent = `<e-translation key="components.password.${tooltipContentTranslationKey}"></e-translation>`;
        return html `
      <e-tooltip content=${tooltipContent}>
        <button
          class=${classList}
          type="button"
          ?disabled=${this.state.isDisabled}
          @click=${this.events.onButtonClick}
        >
          <e-icon icon=${icon}></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createCopyButton() {
        const classList = classnames('e-password__copy_button e-btn e-btn-onlyicon e-inputgroup__item e-inputgroup__item-last', {
            'e-btn-disabled': this.state.isDisabled
        });
        const tooltipContent = '<e-translation key="components.password.copyPassword"></e-translation>';
        return html `
      <e-tooltip content=${tooltipContent}>
        <button
          class=${classList}
          type="button"
          ?disabled=${this.state.isDisabled}
          @click=${this.events.onCopyButtonClick}
          ref=${node => this.refs.copyButton = node}
        >
          <e-icon icon="e-copy"></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createCopyPopup() {
        return html.node `
      <div class="e-padding-m" aria-live="polite" role="status" aria-atomic="true">
        <div>
          <e-icon class="e-margin-right-xs" icon="checkmark"></e-icon>
          <e-translation key="components.password.copyPopup"></e-translation>
        </div>
      </div>
    `;
    }
}
