class EDatagridGroupsState {
  #coreState;
  #contentKey;

  constructor(coreState) {
    this.#coreState = coreState;
    this.requestRender = this.#coreState.requestRender;

    this.#contentKey = '';
  }

  get contentKey() {
    return this.#contentKey;
  }

  set contentKey(value) {
    this.#contentKey = value;
    this.requestRender();
  }
}

export default coreState => new EDatagridGroupsState(coreState);
