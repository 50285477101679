import { html } from 'uhtml';
import classNames from 'clsx';

export class ECardTemplate {

  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    const thumbnailWrapperParts = classNames('thumbnail-wrapper', {
      'hidden': !this.state.isThumbnailWrapperVisible()
    });

    const headerParts = classNames('header', {
      'hidden': !this.state.isHeaderVisible()
    });

    const contentWrapperParts = classNames('content-wrapper', {
      'without-thumbnail-wrapper': !this.state.isThumbnailWrapperVisible(),
      'hidden': !this.state.isContentWrapperVisible()
    });

    return html`
      <style>
        .thumbnail-wrapper ::slotted(img) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        .thumbnail-wrapper ::slotted(iframe) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          height: calc(100% * (1 / var(--e-card-thumbnail-scale, 1)));
          left: 50%;
          max-width: none !important;
          position: absolute;
          top: 0;
          transform: scale(var(--e-card-thumbnail-scale, 1)) translateX(-50%);
          transform-origin: 0 0;
          user-select: none;
          width: var(--e-card-thumbnail-width, auto);
        }
      </style>
      <div class="thumbnail-wrapper" part=${thumbnailWrapperParts}>
        ${this.createThumbnailSlot()}
        <div part=${headerParts}>
          ${this.createHeaderStartSlot()}
          ${this.createHeaderEndSlot()}
        </div>
      </div>
      <div part=${contentWrapperParts}>
        ${this.createContentSlot()}
        ${this.createActionsSlot()}
      </div>
    `;
  }

  createContentSlot() {
    const contentParts = classNames('content', { 'hidden': !this.state.isContentVisible() });

    return html`
      <slot part=${contentParts} name="content" @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createActionsSlot() {
    const actionsParts = classNames('actions', { 'hidden': !this.state.isActionsVisible() });

    return html`
      <slot part=${actionsParts} name="actions" @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createHeaderStartSlot() {
    return html`
      <slot part="header-start" name="header-start" @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createHeaderEndSlot() {
    return html`
      <slot part="header-end" name="header-end" @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createThumbnailSlot() {
    const parts = classNames('thumbnail', {
      'without-content-wrapper': !this.state.isContentWrapperVisible(),
      'hidden': !this.state.isThumbnailVisible()
    });

    return html`
      <slot
        tabindex="-1"
        aria-hidden="true"
        part=${parts}
        name="thumbnail"
        @slotChange=${this.events.onSlotChange}
      ></slot>
    `;
  }
};
