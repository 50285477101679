import { html } from 'uhtml';
import classNames from 'clsx';
import flipper from '../../../utils/flipper';
import EDatagridHeaderTemplate from './templates/header';
import EDatagridColumnHeaderTemplate from './templates/column-header';
import EDatagridBodyTemplate from './templates/body';
import EDatagridFooterTemplate from './templates/footer';
import EDatagridCardViewBodyTemplate from './templates/card-view/body';

export default class EDatagridTemplate {
  constructor(component) {
    this._refs = component.refs;
    this._state = component.state;
    this._events = component.events;

    this._headerTemplate = new EDatagridHeaderTemplate(this._state, this._events, this._refs);
    this._columnHeaderTemplate = new EDatagridColumnHeaderTemplate(this._state, this._events, this._refs);
    this._tableViewBodyTemplate = new EDatagridBodyTemplate(this._state, this._events, this._refs);
    this._footerTemplate = new EDatagridFooterTemplate(this._state, this._events);
    this._cardViewBodyTemplate = new EDatagridCardViewBodyTemplate(this._state, this._events, this._refs);
  }

  createWrapper() {
    return html.node`<e-datagrid-wrapper></e-datagrid-wrapper>`;
  }

  createElement() {
    const datagridClass = classNames('e-datagrid', {
      'e-datagrid-bordered': this._state.isBordered
    });

    if (flipper.isOn('ui_datagrid_card_layout')) {
      return html`
        <div class="${datagridClass}">
          ${this._headerTemplate.create()}
          ${this._state.layout === 'card' ? this.createCardView() : this.createTableView()}
          ${this._footerTemplate.create()}
        </div>
      `;
    }


    const wrapperClass = classNames('', {
      'e-datagrid__table_wrapper': !this._state.isHorizontalScrollDisabled
    });

    const tableClasses = classNames('e-table e-table-datagrid_overview e-datagrid__table', {
      'e-table-datagrid_empty': this._state.isEmpty,
      'e-table-datagrid_hidesearch': this._state.filterState.isSearchHidden,
      'e-table-datagrid_hidepagination': this._state.paginationState.hidePagination
    });

    return html`
      <div class="${datagridClass}">
        ${this._headerTemplate.create()}
        <div class="${wrapperClass}">
          <table class="${tableClasses}" data-e-version="2" ref="${node => this._refs.table = node}">
            ${this._columnHeaderTemplate.create()}
            ${this._tableViewBodyTemplate.create()}
          </table>
        </div>
        ${this._footerTemplate.create()}
      </div>
    `;
  }

  createTableView() {
    const wrapperClass = classNames('', {
      'e-datagrid__table_wrapper': !this._state.isHorizontalScrollDisabled
    });

    const tableClasses = classNames('e-table e-table-datagrid_overview e-datagrid__table', {
      'e-table-datagrid_empty': this._state.isEmpty,
      'e-table-datagrid_hidesearch': this._state.filterState.isSearchHidden,
      'e-table-datagrid_hidepagination': this._state.paginationState.hidePagination
    });

    return html`
      <div class=${wrapperClass}>
        <table class="${tableClasses}" data-e-version="2" ref="${node => this._refs.table = node}">
          ${this._columnHeaderTemplate.create()}
          ${this._tableViewBodyTemplate.create()}
        </table>
      </div>
    `;
  }

  createCardView() {
    return this._cardViewBodyTemplate.create();
  }

  createViewSettingsPopupElement() {
    return this._headerTemplate.createViewSettingsPopupElement();
  }
}
