import splitKeyword from '../../../../../utils/split-search-query/index.js';
import { getTextContent } from './helpers.js';

const contains = (haystack, needle) => haystack.toLowerCase().includes(needle.toLowerCase());
const containsInHTMLContent = (content, word) => contains(getTextContent(content), word);

const contentFormatted = (row, key) => row.formatted[key];
const contentRaw = (row, key) => String(row.raw[key]);
const contentFormattedOrRaw = (row, key) => [contentFormatted(row, key), contentRaw(row, key)].join(' ');

const contentMethods = {
  number: contentFormattedOrRaw,
  currency: contentFormattedOrRaw,
  percent: contentFormattedOrRaw,
  html: contentFormatted,
  default: contentFormatted
};

const cellContent = (row, key, type) => {
  const contentMethod = contentMethods[type] || contentMethods.default;
  return contentMethod(row, key);
};

const isKeywordInRow = (row, word, searchColumns) => {
  const simpleContent = searchColumns
    .filter(({ renderHtml }) => !renderHtml)
    .map(({ contentKey, type }) => cellContent(row, contentKey, type))
    .join(' ');

  const htmlContent = searchColumns
    .filter(({ renderHtml }) => renderHtml)
    .map(({ contentKey, type }) => cellContent(row, contentKey, type))
    .join(' ');

  const foundInSimple = simpleContent && contains(simpleContent, word);

  if (foundInSimple) { return true; }

  const foundInHtml = htmlContent && containsInHTMLContent(htmlContent, word);

  return foundInHtml;
};

const getColumnForKey = (key) => {
  return { contentKey: key };
};

const getSearchColumns = (columnDefinitions, searchIncludes, columnSettings, groupKey) => {
  const searchColumns = new Set([
    ...columnDefinitions.filter(column => !column.searchExclude && !columnSettings[column.contentKey]?.hidden),
    ...searchIncludes.map(getColumnForKey)
  ]);

  if (groupKey) {
    searchColumns.add(getColumnForKey(groupKey));
  }

  return [...searchColumns];
};

const search = (content, [keyword, columnDefinitions, searchKeys, searchIncludes, columnSettings, groupKey]) => {
  if (!columnDefinitions.length || !keyword) { return content; }

  const searchColumns = searchKeys.length > 0 ?
    searchKeys.map(getColumnForKey) :
    getSearchColumns(columnDefinitions, searchIncludes, columnSettings, groupKey);
  const keywords = splitKeyword(keyword);

  return content.filter(row => {
    return keywords.every(keyword => {
      return isKeywordInRow(row, keyword, searchColumns);
    });
  });
};

export default search;
