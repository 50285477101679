import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridItemAction extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      icon: '',
      tooltip: '',
      name: '',
      disabled: false,
      disabledKey: null,
      disabledTooltip: null,
      disabledTooltipKey: null,
      disabledType: '',
      visibleKey: null,
      urlKey: null,
      order: undefined,
      target: '',
      loadingKey: null,
      layoutType: 'table'
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('item-action.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['icon', 'tooltip', 'name', 'disabled', 'disabled-key', 'disabled-tooltip', 'disabled-type', 'visible-key',
      'url-key', 'order', 'disabled-tooltip-key', 'target', 'loading-key'];
  }

  set icon(value) {
    this._state.icon = value;
    this._dispatchEvent();
  }

  set tooltip(value) {
    this._state.tooltip = value;
    this._dispatchEvent();
  }

  set name(value) {
    this._state.name = value;
    this._dispatchEvent();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set disabledKey(value) {
    this._state.disabledKey = value;
    this._dispatchEvent();
  }

  set disabledTooltip(value) {
    this._state.disabledTooltip = value;
    this._dispatchEvent();
  }

  set disabledTooltipKey(value) {
    this._state.disabledTooltipKey = value;
    this._dispatchEvent();
  }

  set disabledType(value) {
    this._state.disabledType = value;
    this._dispatchEvent();
  }

  set visibleKey(value) {
    this._state.visibleKey = value;
    this._dispatchEvent();
  }

  set urlKey(value) {
    this._state.urlKey = value;
    this._dispatchEvent();
  }

  set order(value) {
    const parsedValue = parseInt(value);
    this._state.order = isNaN(parsedValue) ? undefined : parsedValue;
    this._dispatchEvent();
  }

  set target(value) {
    this._state.target = value;
    this._dispatchEvent();
  }

  set loadingKey(value) {
    this._state.loadingKey = value;
    this._dispatchEvent();
  }

  set layoutType(value) {
    this._state.layoutType = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._hasRequiredState()) {
      return;
    }

    this.dispatchEvent(new CustomEvent('item-action.update', {
      bubbles: true,
      detail: Object.assign({}, this._state)
    }));
  }

  _hasRequiredState() {
    return this._state.icon && this._state.tooltip;
  }
}

export default EDatagridItemAction;
