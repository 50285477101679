class EDatagridColumnSettingsState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._settings = {
      card: {},
      table: {}
    };
    this._visibleSettingsPanels = {};
  }

  get userSettings() {
    const userSettings = {};

    Object.keys(this.settingsOnLayout).forEach(columnKey => {
      userSettings[columnKey] = {};

      Object.keys(this.settingsOnLayout[columnKey]).forEach(field => {
        if (this.settingsOnLayout[columnKey][field].user !== undefined) {
          userSettings[columnKey][field] = this.settingsOnLayout[columnKey][field].user;
        }
      });
    });

    return userSettings;
  }

  get activeSettings() {
    const activeSettings = {};

    Object.keys(this.settingsOnLayout).forEach(columnKey => {
      activeSettings[columnKey] = {};

      Object.keys(this.settingsOnLayout[columnKey]).forEach(field => {
        if (this.settingsOnLayout[columnKey][field].user !== undefined) {
          activeSettings[columnKey][field] = this.settingsOnLayout[columnKey][field].user;
        } else {
          activeSettings[columnKey][field] = this.settingsOnLayout[columnKey][field].default;
        }
      });
    });

    return activeSettings;
  }

  get settingsOnLayout() {
    return this._settings[this._coreState.layout];
  }

  setDefaultSetting(contentKey, field, value, layoutType, type = 'default') {
    this._settings = { ...this._settings };

    if (!this._settings[layoutType][contentKey]) {
      this._settings[layoutType][contentKey] = {};
    }

    this._settings[layoutType][contentKey][field] = {
      ...this._settings[layoutType][contentKey][field],
      [type]: value
    };
  }

  setColumnSettings(event) {
    const { contentKey, defaultWidth, hidden, layoutType } = event;

    this.setDefaultSetting(contentKey, 'width', defaultWidth, layoutType);
    this.setDefaultSetting(contentKey, 'hidden', hidden, layoutType);
    this.requestRender();
  }


  setUserSetting(contentKey, field, value, layoutType = this._coreState.layout) {
    this.setDefaultSetting(contentKey, field, value, layoutType, 'user');
    this.requestRender();
  }

  hasUserSettings() {
    return Object.values(this.userSettings).some(settings => Object.keys(settings).length);
  }

  clearUserSetting(contentKey, layoutType = this._coreState.layout) {
    Object.keys(this._settings[layoutType][contentKey]).forEach(field => {
      this._settings[layoutType][contentKey][field] = {
        default: this._settings[layoutType][contentKey][field].default
      };
    });

    this.requestRender();
  }

  clearAllUserSettingsOnCurrentLayout() {
    const layoutType = this._coreState.layout;

    Object.keys(this._settings[layoutType]).forEach(contentKey => {
      this.clearUserSetting(contentKey, layoutType);
    });
    this.requestRender();
  }

  setFromLocalStorageData(localstorageData, layoutType) {
    Object.keys(localstorageData).forEach(contentKey => {
      Object.keys(localstorageData[contentKey]).forEach(field => {
        this.setUserSetting(contentKey, field, localstorageData[contentKey][field], layoutType);
      });
    });
    this.requestRender();
  }

  setSettingsPanelVisibility(contentKey, value) {
    this._visibleSettingsPanels = {
      ...this._visibleSettingsPanels,
      [contentKey]: value
    };
  }

  isSettingsPanelVisible(contentKey) {
    return this._visibleSettingsPanels[contentKey];
  }

  get hasHiddenColumn() {
    return Object.values(this.activeSettings).some(settings => settings?.hidden);
  }

  get hasVisibleColumn() {
    if (!this._coreState.isHeaderVisible || !this._coreState.columnDefinitions.length) { return true; }

    return Object.values(this.activeSettings).some(settings => !settings?.hidden);
  }

  get hasMultipleLayout() {
    return Object.keys(this._settings.card).length && Object.keys(this._settings.table).length;
  }
}

export default coreState => new EDatagridColumnSettingsState(coreState);
