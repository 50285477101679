import { html } from 'uhtml';
import flipper from '../../../../../utils/flipper';
import EDatagridItemActionTemplate from './item-action.js';
import EDatagridCardEmptyStateTemplate from './emptystate.js';

export default class EDatagridCardViewBodyTemplate {

  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
    this._itemActionTemplate = new EDatagridItemActionTemplate(this._state, this._events);
    this._cardEmptyStateTemplate = new EDatagridCardEmptyStateTemplate(this._state, this._events, this._refs);
  }

  create() {
    return html`
      <div class="e-datagrid__card_wrapper">
        ${this.createItemListWrapper()}
        ${this._cardEmptyStateTemplate.create()}
      </div>
    `;
  }

  createItemListWrapper() {
    const filteredColumnDefiniton = this.#getVisibleColumnDefinition();
    if (!filteredColumnDefiniton.length) {
      return html``;
    }

    return html`${this.createItemList()}`;
  }

  createItemWrapper({ itemData }) {
    const filteredColumnDefiniton = this.#getVisibleColumnDefinition();
    const field = filteredColumnDefiniton.find(field => field.type === 'thumbnail');

    return html`
      <e-card thumbnail-original-width=${field?.originalWidth}>
        ${this.createItem({ itemData })}
      </e-card>
    `;
  }

  createContentField({ field, itemData }) {
    return html`
      <div class="e-datagrid__field" slot="content">
        <e-tooltip content="${field.head}" placement="left">
          ${this.createField({ field, itemData })}
        </e-tooltip>
      </div>
    `;
  }

  createThumbnailField({ field, itemData }) {
    return html`
      <iframe
        slot="thumbnail"
        srcdoc="${itemData.formatted[field.contentKey]}"
      ></iframe>
    `;
  }

  createItemList() {
    if (flipper.isOn('ui_datagrid_groups') && this._state.groupsState.contentKey) {
      let lastGroup = null;

      return this._state.visibleContent.reduce((items, itemData) => {
        const currentGroup = itemData.raw[this._state.groupsState.contentKey];
        const isNewGroup = currentGroup !== lastGroup;

        if (isNewGroup) {
          items.push(html`<h3 class="e-datagrid__card_group">${currentGroup}</h3>`);
        }

        items.push(this.createItemWrapper({ itemData }));

        lastGroup = currentGroup;

        return items;
      }, []);
    } else {
      return this._state.visibleContent.map((itemData) => this.createItemWrapper({ itemData }));
    }
  }

  createItem({ itemData }) {
    return html`
      ${this.createFieldList({ itemData })}
      ${this.createItemActions({ itemData })}
    `;
  }

  createFieldList({ itemData }) {
    const filteredColumnDefiniton = this.#getVisibleColumnDefinition();

    return filteredColumnDefiniton
      .map(field => {
        if (field.type === 'thumbnail') {
          return this.createThumbnailField({ field, itemData });
        }

        return this.createContentField({ field, itemData });
      });
  }

  createField({ field, itemData }) {
    return field.renderHtml ? html([itemData.formatted[field.contentKey]]) : itemData.formatted[field.contentKey];
  }

  createItemActions({ itemData }) {
    const itemActions = this._state.itemActions
      .filter(itemAction => itemAction.layoutType === 'card')
      .filter(itemAction => !(itemAction.visibleKey && !(itemData.formatted[itemAction.visibleKey])))
      .map(itemAction => this._itemActionTemplate.create(itemAction, itemData));

    return itemActions;
  }

  #getVisibleColumnDefinition() {
    return this._state.columnDefinitions
      .filter(field => !this._state.columnSettingsState.activeSettings[field.contentKey].hidden);
  }
}
