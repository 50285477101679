import {
  MessageHandler,
  GetTopUrlService,
  UpdateHashService,
  NavigateService,
  SurveyTriggerService,
  ReauthenticateService,
  ConsentService
} from '@emartech/eds-utils-integration';

import './accessibility-handler';
import translationService from './translation-service';
import clientLoggerService from './client-logger';
import purifier from './dom-purify';
import floatUtility from './float';
import popupUtility from './popup';
import dialogUtil from './dialog';
import getAuthenticationTokenUtil from './get-authentication-token';
import getEMSAdminAuthenticationTokenUtil from './get-authentication-token';
import getFlipperServiceUtil from './flipper-service';
import getFeatureServiceUtil from './feature-service';
import setPageTitleUtil from './set-page-title';
import configStore from './config-store';

import flipper from './flipper';
import assetHandler from './asset-handler';
import { confettiHandler } from './confetti';
import floatingPage from './floating-page';
import confirmationDialog from './confirmation-dialog';
import notification from './notification';
import tracker from './track';
import navigation from './navigation-service';
import { topHashChange } from './top-hash-change';
import { uiMonitor } from './ui-monitor';
import { uiAuditor } from './ui-auditor';
import scriptInjector from './script-injector';
import themeHandler from './theme-handler';
import setDocumentLanguage from './set-document-language';

import CSSLogger from './csslogger';
const uiLog = new CSSLogger('css');

import defaultConfig from './config-store/default-config.js';
import { PostMessageHandler } from './postmessage-handler/index.js';

const getTopUrlService = new GetTopUrlService();
const updateHashService = new UpdateHashService();
const navigateService = new NavigateService();
const surveyTriggerService = new SurveyTriggerService();
const reauthenticateService = new ReauthenticateService();
const consentService = new ConsentService();

const init = () => {
  flipper.init();
  assetHandler.init();
  confettiHandler.subscribe();
  floatingPage.subscribe();
  confirmationDialog.subscribe();
  notification.subscribe();
  tracker.init();
  navigation.subscribe();
  topHashChange.subscribe();

  if (flipper.isOn('ui_sentry_integration')) {
    uiMonitor.init();
  }

  uiAuditor.init();

  MessageHandler.init();
  MessageHandler.addService(getTopUrlService);
  MessageHandler.addService(updateHashService);
  MessageHandler.addService(navigateService);
  MessageHandler.addService(surveyTriggerService);
  MessageHandler.addService(reauthenticateService);
  MessageHandler.addService(consentService);

  const postMessageHandler = new PostMessageHandler();
  postMessageHandler.subscribe();
};

const utilsV1 = {
  dialog: dialogUtil,
  openFloatingPage: floatingPage.open,
  closeFloatingPage: floatingPage.close,
  openConsequentialConfirmationDialog: confirmationDialog.openConsequential,
  openDestructiveConfirmationDialog: confirmationDialog.openDestructive,
  openNotification: notification.open,
  getAuthenticationToken: getAuthenticationTokenUtil.suite,
  getEMSAdminAuthenticationToken: getEMSAdminAuthenticationTokenUtil.emsAdmin,
  getConfig: configStore.getLoadedConfig.bind(configStore),
  getCurrentConfig: () => configStore.config,
  getFlipperService: getFlipperServiceUtil,
  getFeatureService: getFeatureServiceUtil,
  navigate: navigation.navigate,
  getTopUrl: navigation.getTopUrl,
  getTopOrigin: navigation.getTopOrigin,
  addTopHashChangeEventListener: topHashChange.addEventListener,
  removeTopHashChangeEventListener: topHashChange.removeEventListener,
  trackEvent: tracker.trackEvent,
  getTranslation: translationService.getTranslation.bind(translationService),
  getTranslations: translationService.getTranslations.bind(translationService),
  sendLog: clientLoggerService.sendLog.bind(clientLoggerService),
  setPageTitle: setPageTitleUtil,
  sanitize: purifier.sanitize.bind(purifier),
  float: floatUtility.float.bind(floatUtility),
  createPopup: popupUtility.createPopup.bind(popupUtility)
};

const utilsV2 = {
  ui: {
    dialog: utilsV1.dialog,
    openFloatingPage: utilsV1.openFloatingPage,
    closeFloatingPage: utilsV1.closeFloatingPage,
    openConsequentialConfirmationDialog: utilsV1.openConsequentialConfirmationDialog,
    openDestructiveConfirmationDialog: utilsV1.openDestructiveConfirmationDialog,
    openNotification: utilsV1.openNotification,
    float: utilsV1.float,
    createPopup: utilsV1.createPopup
  },
  auth: {
    getAuthenticationToken: utilsV1.getAuthenticationToken,
    getEMSAdminAuthenticationToken: utilsV1.getEMSAdminAuthenticationToken,
    reauthenticate: () => reauthenticateService.sendRequest()
  },
  translation: {
    getTranslation: utilsV1.getTranslation,
    getTranslations: utilsV1.getTranslations
  },
  navigation: {
    navigateToRoute: ({ namespace, action, params }) => navigateService.sendRequest({ namespace, action, params }),
    getTopUrl: () => getTopUrlService.sendRequest(),
    getTopOrigin: utilsV1.getTopOrigin,
    updateTopHash: hash => updateHashService.sendRequest(hash),
    addTopHashChangeEventListener: utilsV1.addTopHashChangeEventListener,
    removeTopHashChangeEventListener: utilsV1.removeTopHashChangeEventListener,
    setPageTitle: utilsV1.setPageTitle
  },
  trace: {
    trackEvent: utilsV1.trackEvent,
    sendLog: utilsV1.sendLog
  },
  dom: {
    sanitize: utilsV1.sanitize
  },
  config: {
    getConfig: utilsV1.getConfig,
    getCurrentConfig: utilsV1.getCurrentConfig,
    getFlipperService: utilsV1.getFlipperService,
    getFeatureService: utilsV1.getFeatureService,
    isConsentAccepted: ({ consentId }) => consentService.sendRequest({ consentId })
  },
  survey: {
    triggerSurvey: ({ areaId, triggerName, delay }) => surveyTriggerService.sendRequest({ areaId, triggerName, delay })
  }
};

const windowUtils = {
  ...utilsV1,
  v2: utilsV2
};

export { init, windowUtils, flipper, uiLog, scriptInjector, themeHandler, setDocumentLanguage, defaultConfig };
