import { dateUtils } from '@emartech/ui-framework-utils';
import { getTextContent } from './helpers.js';

const asNumber = value => parseFloat(value);
const asDate = value => {
  const parsedValue = dateUtils.parse(value);
  return dateUtils.isValid(parsedValue) ? parsedValue : null;
};
const asBoolean = value => value ? 1 : 0;

const converters = {
  number: asNumber,
  currency: asNumber,
  percent: asNumber,
  duration: asNumber,
  time: asDate,
  priority: asNumber,
  switch: asBoolean,
  html: value => value ? getTextContent(value).toLowerCase() : '',
  default: value => value ? value.toString().toLowerCase() : ''
};

const getValueConverter = (sortBy, columnDefinitions) => {
  const sortColumn = columnDefinitions.filter(definition => definition.contentKey === sortBy)[0];
  const sortType = sortColumn?.type;
  const isHTMLContent = sortColumn?.renderHtml;

  return converters[sortType] || (isHTMLContent ? converters.html : converters.default);
};

const isEmpty = value => !value && value !== 0;

const getComparator = (groupKey, sortBy, order, converter) => (actualRow, previousRow) => {
  if (groupKey) {
    const groupSort = actualRow.raw[groupKey].localeCompare(previousRow.raw[groupKey]);

    if (groupSort !== 0) { return groupSort; }
  }

  if (!sortBy) { return 0; }

  const actual = converter(actualRow.raw[sortBy]);
  const previous = converter(previousRow.raw[sortBy]);

  if (actual === previous) { return 0; };
  if (isEmpty(actual)) { return 1; }
  if (isEmpty(previous)) { return -1; }

  return (actual > previous ? 1 : -1) * (order === 'asc' ? 1 : -1);
};

const sort = (content, [groupKey, sortBy, order, columnDefinitions]) => {
  if (!groupKey && !sortBy) { return content; }

  const converter = getValueConverter(sortBy, columnDefinitions);
  return [...content].sort(getComparator(groupKey, sortBy, order, converter));
};

export default sort;
