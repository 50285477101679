export class EPasswordState {
    constructor(component) {
        this._inputValue = '';
        this._inputName = '';
        this._placeholder = '';
        this._isDisabled = false;
        this._isReadonly = false;
        this._isInvalid = false;
        this._isPasswordRevealed = false;
        this._isRevealButtonHidden = false;
        this._isCopyButtonVisible = false;
        this.requestRender = component.requestRender.bind(component);
    }
    get inputValue() {
        return this._inputValue;
    }
    set inputValue(value) {
        this._inputValue = value;
        this.requestRender();
    }
    get inputName() {
        return this._inputName;
    }
    set inputName(value) {
        this._inputName = value;
        this.requestRender();
    }
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(value) {
        this._placeholder = value;
        this.requestRender();
    }
    get isDisabled() {
        return this._isDisabled;
    }
    set isDisabled(value) {
        this._isDisabled = value;
        this.requestRender();
    }
    get isReadonly() {
        return this._isReadonly;
    }
    set isReadonly(value) {
        this._isReadonly = value;
        this.requestRender();
    }
    get isInvalid() {
        return this._isInvalid;
    }
    set isInvalid(value) {
        this._isInvalid = value;
        this.requestRender();
    }
    get isPasswordRevealed() {
        return this._isPasswordRevealed;
    }
    ;
    set isPasswordRevealed(value) {
        this._isPasswordRevealed = value;
        this.requestRender();
    }
    get isRevealButtonHidden() {
        return this._isRevealButtonHidden;
    }
    set isRevealButtonHidden(value) {
        this._isRevealButtonHidden = value;
        this.requestRender();
    }
    get isCopyButtonVisible() {
        return this._isCopyButtonVisible;
    }
    set isCopyButtonVisible(value) {
        this._isCopyButtonVisible = value;
        this.requestRender();
    }
}
