import { html } from 'uhtml';
import classnames from 'clsx';

export default class EDatagridItemActionTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  create(itemAction, rowData) {
    const isDisabled = this._isItemActionDisabled(itemAction, rowData);
    const isLoading = this._isItemActionLoading(itemAction, rowData);
    const disabledType = isDisabled && itemAction.disabledType === 'permission';
    const classNames = classnames('e-btn e-btn-borderless e-btn-onlyicon', {
      'e-btn-disabled': isDisabled
    });

    let itemActionElement;

    if (!isLoading && !isDisabled && rowData.raw[itemAction.urlKey]) {
      itemActionElement = this._itemActionLink(itemAction, rowData, rowData.raw[itemAction.urlKey], classNames);
    } else {
      itemActionElement = this._itemActionButton(itemAction, rowData, classNames);
    }

    const tooltip = this._getTooltip(itemAction, rowData);

    return html`
      <e-tooltip slot="actions" content="${tooltip}" permission="${disabledType}" width="200">
        ${itemActionElement}
      </e-tooltip>
    `;
  }

  _itemActionLink(itemAction, rowData, href, classNames) {
    return html`
      <a
        class="${classNames}"
        href=${href}
        target=${href !== null ? itemAction.target : null}
        test-name=${this._state.testHelpers ? itemAction.icon : null}
        @click=${(event) => this._events.onItemActionClick(event, itemAction, rowData)}
      >
        ${this._itemActionIcon(itemAction)}
      </a>
    `;
  }

  _itemActionButton(itemAction, rowData, classNames) {
    if (this._isItemActionLoading(itemAction, rowData)) {
      return html`
        <button
          class="${classNames}"
          type="button"
          test-name=${this._state.testHelpers ? itemAction.icon : null}
          disabled
        >
          <div class="e-btn__loading e-btn__loading-active">
            <e-spinner data-size="small"></e-spinner>
            ${this._itemActionIcon(itemAction)}
          </div>
        </button>
      `;
    }

    const isDisabled = this._isItemActionDisabled(itemAction, rowData);

    return html`
      <button
        class="${classNames}"
        type="button"
        test-name=${this._state.testHelpers ? itemAction.icon : null}
        ?disabled=${isDisabled}
        @click=${(event) => this._events.onItemActionClick(event, itemAction, rowData, isDisabled)}
      >
        ${this._itemActionIcon(itemAction)}
      </button>
    `;
  }

  _itemActionIcon(itemAction) {
    return html`
      <e-icon icon="${itemAction.icon}" type="table"></e-icon>
    `;
  }

  _itemActionSpinner() {
    return html`
      <div class="e-datagrid__item_action e-datagrid__item_action-loading">
        <e-spinner data-size="table"></e-spinner>
      </div>
    `;
  }

  _getTooltip(itemAction, rowData) {
    const isDisabled = this._isItemActionDisabled(itemAction, rowData);
    const { disabledTooltip, tooltip, disabledTooltipKey } = itemAction;
    if (isDisabled && disabledTooltipKey && rowData.raw[disabledTooltipKey]) { return rowData.raw[disabledTooltipKey]; }
    if (isDisabled && disabledTooltip) { return disabledTooltip; }
    return tooltip;
  };

  _isItemActionDisabled(itemAction, rowData) {
    const disabledByKey = !!(itemAction.disabledKey && rowData.raw[itemAction.disabledKey]);
    return itemAction.disabled || disabledByKey || this._state.serverState.isLoading;
  }

  _isItemActionLoading(itemAction, rowData) {
    return !!(itemAction.loadingKey && rowData.raw[itemAction.loadingKey]);
  }
}
