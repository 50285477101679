import { EDSComponent } from '@emartech/eds-utils-component';
import flipper from '../../../utils/flipper/index.js';

export class EDatagridCard extends EDSComponent {

  static componentName = 'datagrid-card';

  connectedCallback() {
    this.addEventListener('column.update', this.#updateLayoutType);
    this.addEventListener('empty-state.update', this.#updateLayoutType);
    this.addEventListener('error-state.update', this.#updateLayoutType);
    this.addEventListener('item-action.update', this.#updateLayoutType);
    this.addEventListener('action.update', this.#updateLayoutType);
  }

  #updateLayoutType(event) {
    if (flipper.isOff('ui_datagrid_card_layout')) {
      event.stopPropagation();
      return;
    }

    if (event.detail.layoutType === 'card') { return; }

    event.target.layoutType = 'card';
    event.stopPropagation();
  }
}
