import EDatagridColumn from '../datagrid-column';

class EDatagridColumnThumbnail extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      type: 'thumbnail',
      originalWidth: null,
      renderHtml: true
    });
  }

  static get observedAttributes() {
    return ['original-width'].concat(super.observedAttributes);
  }

  set renderHtml(_) { }

  set originalWidth(value) {
    this._state.originalWidth = value || null;
    this._dispatchEvent();
  }
}

export default EDatagridColumnThumbnail;
