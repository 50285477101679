import autoBind from 'auto-bind';
import isMatch from 'lodash.ismatch';
import { queryKeyboardFocusableAll } from '@emartech/ui-framework-utils/dom';
import { popupUtility } from '../../../utils/popup';
import Storage from '../../../utils/storage';
import flipper from '../../../utils/flipper';
import constants from './constants';
import { calculatePageSize } from './utils';
import { allowedLayouts } from './index.js';

const storage = new Storage();
const OPTION_PREFIX = constants.filter.select.optionPrefix;
const OPTION_ALL = constants.filter.select.all;

export default class EDatagridEvents {
  constructor(component) {
    autoBind(this);
    this._component = component;
    this._state = component.state;
    this._refs = component.refs;
    this._utils = component.utils;
    this._dispatchEvent = component.dispatchEvent.bind(component);

    this._searchDebounceTimeout = null;
  }

  onResize() {
    if (this._state.isHorizontalScrollDisabled) { return; }

    const currentWidth = window.getComputedStyle(this._refs.wrapper).width;
    if (currentWidth === this._state.wrapperWidth) { return; }

    this._state.wrapperWidth = currentWidth;

    const tableRef = this._refs.table;
    const wrapper = this._refs.wrapper;
    if (!tableRef || !wrapper) { return; }

    this._state.isHorizontalScrollVisible = tableRef.scrollWidth > wrapper.clientWidth;
  }

  onUpdateColumn(event) {
    event.stopPropagation();
    event.target.addEventListener('column.delete', this.onDeleteColumn);

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      if (flipper.isOn('ui_datagrid_card_layout')) {
        this._state.columnSettingsState.setColumnSettings(event.detail);
      } else {
        this._state.columnSettingsState.setDefaultSetting(event.detail.contentKey, 'width', event.detail.defaultWidth);
        this._state.columnSettingsState.setDefaultSetting(event.detail.contentKey, 'hidden', event.detail.hidden);
      }
    } else {
      if (!this._state.columnSettings[event.detail.contentKey]?.width) {
        this._state.setColumnSettings(event.detail.contentKey, 'width', event.detail.defaultWidth);
      }
    }

    this._state.upsertColumnDefinition(Object.assign({}, event.detail, { component: event.target }));
  }

  onDeleteColumn(event) {
    this._state.deleteColumnDefinition(event.detail.uuid);
  }

  onUpdateAction(event) {
    event.stopPropagation();
    event.target.addEventListener('action.delete', this.onDeleteAction);
    this._state.upsertAction(Object.assign({}, event.detail, { component: event.target }));
  }

  onDeleteAction(event) {
    this._state.deleteAction(event.detail.uuid);
  }

  onUpdateGroups(event) {
    event.stopPropagation();
    event.target.addEventListener('groups.delete', this.onDeleteGroups);
    this._state.groupsState.contentKey = event.detail.contentKey;
  }

  onDeleteGroups() {
    this._state.groupsState.contentKey = '';
  }

  onUpdateItemAction(event) {
    event.stopPropagation();
    event.target.addEventListener('item-action.delete', this.onDeleteItemAction);
    this._state.upsertItemAction(Object.assign({}, event.detail, { component: event.target }));
  }

  onDeleteItemAction(event) {
    this._state.deleteItemAction(event.detail.uuid);
  }

  onUpdateFilter(event) {
    event.stopPropagation();
    event.target.addEventListener('filter.delete', this.onDeleteFilter);
    this._state.filterState.upsertFilter(Object.assign({}, event.detail, { component: event.target }));
    this._state.filterState.isFiltersOpen = this._state.filterState.isFiltersOpen || this._isAdvancedFilterInUse();
  }

  onDeleteFilter(event) {
    this._state.filterState.deleteFilter(event.detail.uuid);
  }

  onUpdateBulkAction(event) {
    event.stopPropagation();
    event.target.addEventListener('bulk-action.delete', this.onDeleteBulkAction);
    this._state.bulkState.upsertBulkAction(Object.assign({}, event.detail, { component: event.target }));
  }

  onDeleteBulkAction(event) {
    this._state.bulkState.deleteBulkAction(event.detail.uuid);
  }

  onUpdateBulkGroup(event) {
    event.stopPropagation();
    event.target.addEventListener('bulk-group.delete', this.onDeleteBulkGroup);
    this._state.bulkState.upsertBulkGroup(Object.assign({}, event.detail, { component: event.target }));
  }

  onDeleteBulkGroup(event) {
    this._state.bulkState.deleteBulkGroup(event.detail.uuid);
  }

  onUpdateBulkToggle(event) {
    event.stopPropagation();
    event.target.addEventListener('bulk-toggle.delete', this.onDeleteBulkToggle);

    const { icon, label } = event.detail;
    this._state.bulkState.bulkToggle = { icon, label };
  }

  onDeleteBulkToggle() {
    this._state.bulkState.bulkToggle = null;
  }

  onUpdateEmptyState(event) {
    if (flipper.isOn('ui_datagrid_card_layout')) {
      if (event.detail.layoutType === 'card') {
        event.target.addEventListener('empty-state.delete', this.onDeleteEmptyStateCard);
        this._refs.customEmptyStateCard = event.target;
      } else {
        event.target.addEventListener('empty-state.delete', this.onDeleteEmptyState);
        this._refs.customEmptyState = event.target;
      }
    } else {
      event.target.addEventListener('empty-state.delete', this.onDeleteEmptyState);
      this._refs.customEmptyState = event.target;
    }
    this._component.requestRender();
  }

  onDeleteEmptyState() {
    this._refs.customEmptyState = null;
    this._component.requestRender();
  }

  onDeleteEmptyStateCard() {
    this._refs.customEmptyStateCard = null;
    this._component.requestRender();
  }

  onUpdateErrorState(event) {
    if (flipper.isOn('ui_datagrid_card_layout')) {
      if (event.detail.layoutType === 'card') {
        event.target.addEventListener('error-state.delete', this.onDeleteErrorStateCard);
        this._refs.customErrorStateCard = event.target;
      } else {
        event.target.addEventListener('error-state.delete', this.onDeleteErrorState);
        this._refs.customErrorState = event.target;
      }
    } else {
      event.target.addEventListener('error-state.delete', this.onDeleteErrorState);
      this._refs.customErrorState = event.target;
    }
    this._component.requestRender();
  }

  onDeleteErrorState() {
    this._refs.customErrorState = null;
    this._component.requestRender();
  }

  onDeleteErrorStateCard() {
    this._refs.customErrorStateCard = null;
    this._component.requestRender();
  }

  onBulkToggleClick() {
    this._state.bulkState.toggleBulkMode();
  };

  onEscapeAction(event) {
    if (event.keyCode === 27 && this._state.priorityState.isPriorityModeOpen) {
      event.preventDefault();

      this.onPriorityModeButtonClick();
    }
  }

  onConfigChange() {
    this._state.updateFormats();
    this._state.updateTranslations();
    this._state.updateLanguage();
  }

  onLoadState() {
    const sessionSettings = this._getSettingsFromStorages();

    if (sessionSettings) {
      this._state.filterState.filterValues = sessionSettings.filter || this._state.filterState.filterValues;

      if (!this._state.filterState.searchKeyword.default) {
        const searchKeyword = sessionSettings.searchKeyword || '';
        this._state.filterState.search(searchKeyword);
      }

      this._state.filterState.sorting = sessionSettings.sorting || this._state.filterState.sorting;
      this._setPageNumber(sessionSettings);
    }

    this._loadColumnSettingsFromLocalStorage();
  }

  onLoadPageState() {
    const sessionSettings = this._getSettingsFromStorages();

    if (sessionSettings) {
      this._setPageNumber(sessionSettings);
    }
  }

  onSearchInputChange(event) {
    if (this._searchDebounceTimeout) {
      clearTimeout(this._searchDebounceTimeout);
    }

    this._searchDebounceTimeout = setTimeout(() => {
      this._state.filterState.search(event.target.value);
      this._state.filterState.searchKeyword = {
        ...this._state.filterState.searchKeyword,
        default: false
      };

      this._upsertFilterSessionState();
      this._dispatchFilterChange();
    }, 300);
  }

  onPriorityModeButtonClick() {
    this._state.priorityState.togglePriorityMode();

    if (!this._state.priorityState.isPriorityModeOpen) {
      this._firePriorityFinishAction();
      this._component.querySelector('.e-datagrid__priority_button .e-btn').focus();
    }
  }

  onAdvancedFilterButtonClick() {
    this._state.filterState.isFiltersOpen = !this._state.filterState.isFiltersOpen;
  }

  onViewSettingsButtonClick() {
    if (this._utils.viewSettingsPopup && this._utils.viewSettingsPopup.isOpened) {
      this._utils.viewSettingsPopup.destroy();
      return;
    }

    const openerElement = this._refs.viewSettingsButton;
    const popupElement = this._refs.viewSettingsPopup;

    this._utils.viewSettingsPopup = popupUtility.createPopup(openerElement, popupElement, {
      elementToFocusOnOpen: queryKeyboardFocusableAll(popupElement, { includeHidden: true })?.[0] || null,
      elementToFocusOnClose: openerElement,
      onAfterOpen: () => this._state.viewSettingsVisible = true,
      onAfterClose: () => this._state.viewSettingsVisible = false
    });

    this._utils.viewSettingsPopup.open();
  }

  onViewSettingsHideColumnButtonClick(contentKey) {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      const columnHidden = this._state.columnSettingsState.activeSettings[contentKey].hidden;
      this._state.columnSettingsState.setUserSetting(contentKey, 'hidden', !columnHidden);
    } else {
      const columnHidden = this._state.columnSettings[contentKey].hidden;
      this._state.setColumnSettings(contentKey, 'hidden', !columnHidden);
    }

    this._saveColumnSettingsToLocalStorage();
  }

  onViewSettingsSortColumnButtonClick(contentKey) {
    const field = this._state.getColumnDefinitionByColumnKey(contentKey);

    const priorityState = this._state.priorityState;
    if (!field.sortable || (priorityState.isPriorityModeOpen && !priorityState.isPriorityField(field))) { return; }

    this._state.filterState.toggleSorting(contentKey);
    this._upsertFilterSessionState();

    const { key, order } = this._state.filterState.sorting;

    this._component.dispatchEvent(new CustomEvent('sort', {
      detail: {
        contentKey: key,
        order
      }
    }));
  }

  onViewSettingsSwitchChange(event, contentKey) {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      this._state.columnSettingsState.setUserSetting(contentKey, 'hidden', !event.target.checked);
    } else {
      this._state.setColumnSettings(contentKey, 'hidden', !event.target.checked);
    }

    this._saveColumnSettingsToLocalStorage();
  }

  onViewSettingsResetButtonClick() {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      if (flipper.isOn('ui_datagrid_card_layout')) {
        this._state.columnSettingsState.clearAllUserSettingsOnCurrentLayout();
      } else {
        this._state.columnSettingsState.clearAllUserSettings();
      }
    } else {
      this._state.columnDefinitions.forEach(columnDefinition => {
        this._state.setColumnSettings(columnDefinition.contentKey, 'hidden', false);
      });
    }

    this._saveColumnSettingsToLocalStorage();
  }

  onFilterChange(event, filter) {
    const value = this._getFilterValue(event, filter);
    this._state.filterState.filter(filter, value);
    this._upsertFilterSessionState();
    this._fireFilterAction(filter.component, value);
    this._dispatchFilterChange();
  }

  onActionButtonClick(event, action) {
    if (action.dialog && document.querySelector(action.dialog)) {
      document.querySelector(action.dialog).open();
    }

    action.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        originalEvent: event
      }
    }));

    action.component.dispatchEvent(new CustomEvent(`datagrid.action.${action.name}`, {
      bubbles: true
    }));
  }

  onBulkActionButtonClick(action) {
    action.component.dispatchEvent(
      new CustomEvent('trigger', {
        detail: {
          data: this._state.bulkState.selection,
          isSelectionInverted: this._state.bulkState.isSelectionInverted
        }
      })
    );
  }

  onBulkSelectionCheckboxChange() {
    const visibleContent = this._state.visibleContent.map(visibleRow => visibleRow.raw);
    const interSection = visibleContent.filter(row =>
      this._state.bulkState.selection.find(selectedItem => isMatch(row, selectedItem)));

    const isAllVisibleInSelection = visibleContent.length === interSection.length;
    const isNonOfVisibleInSelection = !interSection.length;
    const isAllVisibleSelected = (isAllVisibleInSelection && !this._state.bulkState.isSelectionInverted) ||
      (isNonOfVisibleInSelection && this._state.bulkState.isSelectionInverted);

    if (isAllVisibleSelected) {
      this._state.bulkState.deselectVisible();
    } else {
      this._state.bulkState.selectVisible();
    }
    this._dispatchSelectEvent();
  }

  onBulkDropdowSelectVisibleClick() {
    if (this._state.serverState.isLoading) { return; }
    this._state.bulkState.selectVisible();
    this._dispatchSelectEvent();
  }

  onBulkDropdowDeselectVisibleClick() {
    if (this._state.serverState.isLoading) { return; }
    this._state.bulkState.deselectVisible();
    this._dispatchSelectEvent();
  }

  onBulkDropdowSelectAllClick() {
    if (this._state.serverState.isLoading) { return; }
    this._state.bulkState.selectAll();
    this._dispatchSelectEvent();
  }

  onBulkDropdowDeselectAllClick() {
    if (this._state.serverState.isLoading) { return; }
    this._state.bulkState.deselectAll();
    this._dispatchSelectEvent();
  }

  onColumnHeaderSortClick(field) {
    const priorityState = this._state.priorityState;
    if (!field.sortable || (priorityState.isPriorityModeOpen && !priorityState.isPriorityField(field))) { return; }

    this._state.filterState.toggleSorting(field.contentKey);
    this._upsertFilterSessionState();

    const { key: contentKey, order } = this._state.filterState.sorting;

    this._component.dispatchEvent(new CustomEvent('sort', {
      detail: {
        contentKey,
        order
      }
    }));
  }

  onBulkSelectRowCheckboxChange(event, rowData) {
    if (this._state.serverState.isLoading) { return; }

    event.preventDefault();

    this._state.bulkState.toggleSelection(rowData.raw);

    this._dispatchSelectEvent();
  }

  onPriorityModeRowRadioChange(event, rowData) {
    if (this._state.serverState.isLoading) { return; }
    if (this._state.priorityState.isPriorityModeDisabledOnRow(rowData)) { return; }

    event.preventDefault();

    this._state.priorityState.selectedToPrioritize = rowData.raw;
  }

  onColumnSwitchChange(rowData, columnDefinition, isLoading, isDisabled) {
    if (isLoading || isDisabled) { return; }

    this._state.serverState.addToSwitchLoading(rowData.raw, columnDefinition.contentKey);

    columnDefinition.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        data: rowData.raw,
        formattedData: rowData.formatted,
        resolve: (newRowData) => { this._changeRowData(columnDefinition.contentKey, rowData.raw, newRowData); },
        reject: () => { this._removeLoadingItem(columnDefinition.contentKey); }
      }
    }));
  }

  onColumnSettingsButtonClick(event, contentKey) {
    event.stopImmediatePropagation();

    this._ensureColumnSettingsPopup(contentKey, event.currentTarget);

    this._utils.columnSettingsPopups[contentKey].toggle();
  }

  onColumnSettingsVisibilityChange(_, contentKey) {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      this._state.columnSettingsState.setUserSetting(contentKey, 'hidden', true);
    } else {
      this._state.setColumnSettings(contentKey, 'hidden', true);
    }

    this._saveColumnSettingsToLocalStorage();
    this._utils.columnSettingsPopups[contentKey].toggle();
  }

  onColumnSettingsWidthChange(event, contentKey) {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      const value = event.detail.value;
      this._state.columnSettingsState.setUserSetting(contentKey, 'width', value === 'auto' ? undefined : value);
    } else {
      this._state.setColumnSettings(contentKey, 'width', event.detail.value);
    }

    this._saveColumnSettingsToLocalStorage();
  }

  onColumnSettingsResetChange(_, contentKey) {
    if (flipper.isOn('ui_datagrid_column_hidden')) {
      this._state.columnSettingsState.clearUserSetting(contentKey);
    } else {
      this._state.clearColumnSettings(contentKey);
    }

    this._saveColumnSettingsToLocalStorage();
  }

  onItemActionColumnClick(event) {
    event.stopPropagation();
  }

  onItemActionClick(event, itemAction, rowData, isDisabled) {
    if (isDisabled || this._state.serverState.isLoading) { return; };

    const { raw, formatted } = rowData;

    itemAction.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        originalEvent: event,
        data: raw,
        formattedData: formatted
      }
    }));

    this._dispatchEvent(new CustomEvent(`datagrid.item-action.${itemAction.name}`, {
      bubbles: true,
      detail: {
        data: raw
      }
    }));
  }

  onPriorityTopButtonClick() {
    const selectedRowPriorityPosition = this._state.priorityState.selectedToPrioritizePosition;

    this._reorderContent('top', selectedRowPriorityPosition);
    this._firePriorityTriggerAction();
  }

  onPriorityUpButtonClick() {
    const selectedRowPriorityPosition = this._state.priorityState.selectedToPrioritizePosition;

    if (selectedRowPriorityPosition === 1) { return; }

    this._moveContent('up', selectedRowPriorityPosition);
    this._firePriorityTriggerAction();
  }

  onPriorityDownButtonClick() {
    const selectedRowPriorityPosition = this._state.priorityState.selectedToPrioritizePosition;

    if (selectedRowPriorityPosition === this._state.content.length) { return; }

    this._moveContent('down', selectedRowPriorityPosition);
    this._firePriorityTriggerAction();
  }

  onPriorityBottomButtonClick() {
    const selectedRowPriorityPosition = this._state.priorityState.selectedToPrioritizePosition;

    this._reorderContent('bottom', selectedRowPriorityPosition);
    this._firePriorityTriggerAction();
  }

  onPageSizeSelectChange(event) {
    const pageSize = calculatePageSize(event.target.value);
    this._dispatchEvent(new CustomEvent('pagesizechange', { detail: { value: pageSize } }));
    this._state.paginationState.pageSize = pageSize;
    this._savePageSize();
  }

  onPaginationChange(event) {
    this._state.paginationState.pageNumber = parseInt(event.detail.page);
    this._dispatchEvent(new CustomEvent('pagechange', {
      detail: {
        value: this._state.paginationState.pageNumber,
        trigger: event.detail.trigger
      }
    }));
    this._upsertFilterSessionState();
  }

  onLayoutDisplayChange(event) {
    this._state.layout = event.detail.value;
  }

  _isAdvancedFilterInUse() {
    const advancedFilters = this._state.filterState.filters.filter(filter => filter.advanced);
    const advancedFiltersInUse = advancedFilters.filter(filter => {
      return this._state.filterState.filterValues[filter.contentKey];
    });

    return !!advancedFiltersInUse.length;
  }

  _getSettingsFromStorages() {
    this._loadPageSizeFromLocalStorage();
    const sessionSettings = storage.getItem(`e.datagrid.${this._state.id}.sessionSettings`, 'session');

    if (sessionSettings) {
      try {
        return JSON.parse(sessionSettings);
      } catch (parseError) {
        return null;
      }
    }
  }

  _setPageNumber(sessionSettings) {
    const pageNumber = Math.ceil(sessionSettings.firstVisibleElement / this._state.paginationState.pageSize);
    this._state.paginationState.pageNumber = pageNumber || 1;

    if (sessionSettings.itemCount !== this._state.paginationState.itemCount) {
      this._state.paginationState.pageNumber = 1;
    }
  }

  _loadPageSizeFromLocalStorage() {
    const localstoragePageSizeKey = !!this._state.id ? `e.datagrid.${this._state.id}.pageSize` : 'e.datagrid.pageSize';
    const localstoragePageSize = storage.getItem(localstoragePageSizeKey, 'local');

    if (!localstoragePageSize) { return; }

    this._state.paginationState.pageSize = calculatePageSize(localstoragePageSize);
  }

  _loadColumnSettingsFromLocalStorage() {
    if (flipper.isOn('ui_datagrid_card_layout')) {
      const legacyLocalstorageKey = `e.datagrid.${this._state.id}.columnSettings`;
      const legacyColumnSettings = storage.getItem(legacyLocalstorageKey, 'local');

      if (legacyColumnSettings) {
        storage.setItem(`${legacyLocalstorageKey}.table`, legacyColumnSettings);
        storage.removeItem(legacyLocalstorageKey);
      }

      allowedLayouts.forEach(layoutType => {
        const localstorageKey = `e.datagrid.${this._state.id}.columnSettings.${layoutType}`;
        const localstorageData = storage.getItem(localstorageKey, 'local');

        if (!localstorageData) { return; }

        try {
          if (flipper.isOn('ui_datagrid_column_hidden')) {
            const parsedLocalstorageData = JSON.parse(localstorageData);
            this._state.columnSettingsState.setFromLocalStorageData(parsedLocalstorageData, layoutType);
          } else {
            this._state.columnSettings = JSON.parse(localstorageData);
          }
        } catch (parseError) {}
      });
    } else {
      const localstorageKey = `e.datagrid.${this._state.id}.columnSettings`;
      const localstorageData = storage.getItem(localstorageKey, 'local');

      if (!localstorageData) { return; }

      try {
        if (flipper.isOn('ui_datagrid_column_hidden')) {
          const parsedLocalstorageData = JSON.parse(localstorageData);
          this._state.columnSettingsState.setFromLocalStorageData(parsedLocalstorageData);
        } else {
          this._state.columnSettings = JSON.parse(localstorageData);
        }
      } catch (parseError) {}
    }
  }

  _moveContent(direction, selectedRowPriorityPosition) {
    const columnName = this._state.priorityState.priorityColumn.contentKey;
    const selectedRow = this._state.priorityState.selectedToPrioritize;

    const changedPriority = direction === 'down' ? selectedRowPriorityPosition + 1 : selectedRowPriorityPosition - 1;
    const neighbourRow = this._getNeighbourRowByPriority(changedPriority);

    const content = this._state.content.filter(row => {
      return row[columnName] !== selectedRowPriorityPosition && row[columnName] !== changedPriority;
    });

    selectedRow[columnName] = changedPriority;
    neighbourRow[columnName] = selectedRowPriorityPosition;

    content.push(selectedRow);
    content.push(neighbourRow);

    this._state.content = content;

    this._setPageNumberByPriority(changedPriority);
  }

  _reorderContent(move, selectedRowPriorityPosition) {
    const columnName = this._state.priorityState.priorityColumn.contentKey;
    const selectedRow = this._state.priorityState.selectedToPrioritize;

    const content = this._state.content.map(row => {
      if (move === 'top' && row[columnName] < selectedRowPriorityPosition) {
        row[columnName]++;
      } else if (move === 'bottom' && row[columnName] > selectedRowPriorityPosition) {
        row[columnName]--;
      }

      return row;
    });

    const changedPriority = move === 'top' ? 1 : this._state.content.length;
    selectedRow[columnName] = changedPriority;

    this._state.content = content;

    this._setPageNumberByPriority(changedPriority);
  }

  _getNeighbourRowByPriority(changedPriority) {
    const columnName = this._state.priorityState.priorityColumn.contentKey;
    return this._state.content.filter(row => row[columnName] === changedPriority)[0];
  }

  _firePriorityTriggerAction() {
    this._state.priorityState.priorityColumn.component.dispatchEvent(new CustomEvent('trigger', {
      detail: {
        data: this._state.content,
        targetRow: this._state.priorityState.selectedToPrioritize
      }
    }));
  }

  _setPageNumberByPriority(changedPriority) {
    const filteredContent = this._state.filterState.filteredContent;
    const visiblePosition = filteredContent.map(content => content.raw.priority).indexOf(changedPriority) + 1;
    this._state.paginationState.pageNumber = Math.ceil(visiblePosition / this._state.paginationState.pageSize);
  }

  _dispatchFilterChange() {
    this._dispatchEvent(new CustomEvent('filter', {
      detail: {
        filterValues: this._state.filterState.filterValues,
        searchKeyword: this._state.filterState.searchKeyword
      }
    }));
  }

  _upsertFilterSessionState() {
    if (!this._state.id) { return; }

    const filterValuesToSave = Object.keys(this._state.filterState.filterValues).reduce((prev, current) => {
      if (!this._state.filterState.filterValues[current].default) {
        prev[current] = this._state.filterState.filterValues[current];
      }

      return prev;
    }, {});

    const sessionSettings = JSON.stringify({
      searchKeyword: this._state.filterState.searchKeyword.keyword,
      filter: filterValuesToSave,
      sorting: this._state.filterState.sorting,
      firstVisibleElement: (this._state.paginationState.pageNumber - 1) * this._state.paginationState.pageSize + 1,
      itemCount: this._state.paginationState.itemCount
    });

    storage.setItem(`e.datagrid.${this._state.id}.sessionSettings`, sessionSettings, 'session');
  }

  _firePriorityFinishAction() {
    this._state.priorityState.priorityColumn.component.dispatchEvent(new CustomEvent('finish', {
      detail: {
        data: this._state.content
      }
    }));
  }

  _getFilterValue(event, filter) {
    if (filter.type === 'checkbox' || filter.type === 'switch') {
      return event.target.checked;
    } else if (filter.type === 'daterange') {
      const value = event.detail.value;
      return value !== '' ? { start: { date: value.start }, end: { date: value.end } } : '';
    } else if (filter.type === 'multiselect') {
      if (filter.hasOperator) {
        const filterElement = event.target.closest('.e-datagrid__filter');
        const multiselectElement = filterElement.querySelector('e-multiselect');
        const selectElement = filterElement.querySelector('e-select');

        return {
          operator: selectElement.value,
          value: multiselectElement.value.map(option => option.value.slice(OPTION_PREFIX.length))
        };
      }

      return event.target.value.map(option => option.value.slice(OPTION_PREFIX.length));
    } else {
      return event.target.value === OPTION_ALL ? null : event.target.value.slice(OPTION_PREFIX.length);
    }
  }

  _fireFilterAction(filterElement, value) {
    filterElement.dispatchEvent(new CustomEvent('trigger', { detail: { value } }));
  }

  _changeRowData(contentKey, oldRowData, newRowData) {
    this._state.changeRowData(oldRowData, newRowData);
    this._state.serverState.removeFromSwitchLoading(contentKey);
  }

  _removeLoadingItem(contentKey) {
    this._state.serverState.removeFromSwitchLoading(contentKey);
  }

  _savePageSize() {
    if (!!this._state.id) {
      storage.setItem(`e.datagrid.${this._state.id}.pageSize`, this._state.paginationState.pageSize, 'local');
    } else {
      storage.setItem('e.datagrid.pageSize', this._state.paginationState.pageSize, 'local');
    }
  }

  _ensureColumnSettingsPopup(contentKey, openerElement) {
    if (this._utils.columnSettingsPopups[contentKey]) { return; }

    const popup = popupUtility.createPopup(openerElement, this._refs.columnSettings[contentKey].popup, {
      itemToFocusOnClose: openerElement
    });

    this._utils.columnSettingsPopups[contentKey] = popup;
  }

  _saveColumnSettingsToLocalStorage() {
    if (!this._state.id) { return; }

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      if (this._state.columnSettingsState.hasUserSettings()) {
        const columnSettingsJson = JSON.stringify(this._state.columnSettingsState.userSettings);
        if (flipper.isOn('ui_datagrid_card_layout')) {
          storage.setItem(`e.datagrid.${this._state.id}.columnSettings.${this._state.layout}`, columnSettingsJson);
        } else {
          storage.setItem(`e.datagrid.${this._state.id}.columnSettings`, columnSettingsJson);
        }
      } else {
        if (flipper.isOn('ui_datagrid_card_layout')) {
          storage.removeItem(`e.datagrid.${this._state.id}.columnSettings.${this._state.layout}`);
        } else {
          storage.removeItem(`e.datagrid.${this._state.id}.columnSettings`);
        }
      }
    } else {
      const hasColumnSettings = Object.values(this._state.columnSettings)
        .some(settings => Object.keys(settings).length);

      if (hasColumnSettings) {
        const columnSettingsJson = JSON.stringify(this._state.columnSettings);
        storage.setItem(`e.datagrid.${this._state.id}.columnSettings`, columnSettingsJson);
      } else {
        storage.removeItem(`e.datagrid.${this._state.id}.columnSettings`);
      }
    }
  }

  _dispatchSelectEvent() {
    this._component.dispatchEvent(new CustomEvent('select', {
      detail: {
        selectedItems: this._component.selectedItems
      }
    }));
  }
}
